import axios from "axios"
import { DateTime } from "luxon";

const createWork = {
    serviceID: null,
    priceList: null,

    dateStart: null,
    dateEnd: null,
    timeStart: null,
    timeEnd: null,

    distanceValue: null,

    package: null,

    remove() {
        const previous = this.$root.previousElementSibling
        if (previous) previous.style.display = 'table-row'
        this.$root.remove()
    },

    getServiceId(event) { this.serviceID = event.detail },

    get startDateObject() {
        return (this.dateStart && this.timeStart)
            ? DateTime.fromISO(`${this.dateStart}T${this.timeStart}`)
            : null
    },

    get endDateObject() {
        return (this.dateEnd && this.timeEnd)
            ? DateTime.fromISO(`${this.dateEnd}T${this.timeEnd}`)
            : null
    },

    get timeValueObject() {
        return (this.startDateObject && this.endDateObject)
            ? this.endDateObject.diff(this.startDateObject)
            : null
    },

    get timeValue() { return this.timeValueObject?.toFormat('hh:mm') ?? '00:00' },

    get prices() {
        return (this.serviceID && this.priceList)
            ? axios
                .get(`/price_list/${this.priceList}`)
                    .then(resp =>
                        resp.data.find(item => item.service_id == Number(this.serviceID)) ?? {})
                    .catch(err => {
                        console.log(`Erro ao carregar as tabelas de preço`)
                        console.log(err)
                        return {}
                    })
            : new Promise(() => ({}))
    },

    get timeCost() { return this.prices.then(p => p?.per_hour ?? null) },

    get timeTotal() {
        return this.timeValueObject
            ? this.timeCost.then(cost => (cost * this.timeValueObject.shiftTo('hours').hours).toFixed(2))
            : new Promise(resolve => resolve(0))
    },

    get distanceCost() { return this.prices.then(p => p?.per_km ?? null) },

    get distanceTotal() {
        return this.distanceValue
            ? this.distanceCost.then(cost => (cost * this.distanceValue).toFixed(2))
            : new Promise(resolve => resolve(0))
    },

    get workTotal() {
        return Promise.all([this.distanceTotal, this.timeTotal])
            .then(values => {
                return values
                    .reduce((acc, val) => acc + Number(val), 0)
                    .toFixed(2)
            })
    },
}

export default createWork
